import {
    GET_CLIENTS,
    EDIT_CLIENTS,
    DELETE_CLIENTS,
    SELECT_CLIENT,
    ADD_CLIENT,
    LOADING_CLIENT
} from "./types";
import axios from 'axios';
export const getClients = () => dispatch => {
    axios
        .get('/api/clients')
        .then(res => {
            dispatch({
            type: GET_CLIENTS,
            payload: res.data
        })})
};

export const selectClient = (id) => {
    return {
        type: SELECT_CLIENT,
        payload: id
    }
}

export const addClient = (client) => dispatch => {
    axios
        .post('/api/clients', client)
        .then(res => dispatch({
            type: ADD_CLIENT,
            payload: res.data
        }))
}

export const setClientsLoading = () => {
    return {
        type: LOADING_CLIENT
    }
}

export const editClient = (client) => dispatch => {
    axios
        .patch(`/api/clients/${client._id}`, client)
        .then(res => dispatch({
            type: EDIT_CLIENTS,
            payload: res.data
        }))
}

export const deleteClient = (id) => dispatch => {
    axios
        .delete(`/api/clients/${id}`)
        .then(res => dispatch({
            type: DELETE_CLIENTS,
            payload: id
        }))
}
