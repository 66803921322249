import React, {Component} from 'react';
import { connect } from "react-redux";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {editSKP} from "../actions/skpActions";

class SkpEdit extends Component {

	state = {
		modal: false,
		name: '',
		nip: '',
		_id: ''
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal
		})
		const {selectedSKP} = this.props.SKP;

		if(selectedSKP){
			const cl = this.props.SKP.SKP.filter(client => client._id === selectedSKP)[0];
			console.log(cl);
			if(cl){
				this.setState({
					name: cl.name,
					nip: cl.nip,
					_id: cl._id
				})
			}
		}
	}

	onChange = (e) => {
		this.setState({[e.target.name]: e.target.value });
	}

	onSubmit = (e) => {
		e.preventDefault();

		const newSKP = {
			name: this.state.name,
			nip: this.state.nip,
			_id: this.state._id
		}

		this.props.editSKP(newSKP);
		this.toggle();
	}

	render(){

		if(!this.props.SKP.selectedSKP){
			return null;
		}

		return (
			<>
				<Button className="marginBottom" color="success" onClick={this.toggle}>Edytuj klienta</Button>
				<Modal
					isOpen={this.state.modal}
					toggle={this.toggle}
				>
					<ModalHeader toggle={this.toggle}>Edycja klienta</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label style={{marginBottom: '0.3rem'}} for="name">Nazwa firmy</Label>
								<Input type="text" id="name"
								       name="name" placeholder="Nazwa firmy"
								       onChange={this.onChange}
								       style={{marginBottom: '0.7rem'}}
								       value={this.state.name} />
								<Label style={{marginBottom: '0.3rem'}} for="nip">NIP</Label>
								<Input type="text" id="nip"
								       name="nip" placeholder="NIP"
								       onChange={this.onChange}
								       style={{marginBottom: '0.7rem'}}
								       value={this.state.nip}/>
								<br />
								<Button className="saveButton" color="dark">Edytuj</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
			</>
		)
	}
}

const mapStateToProps = (state) => ({
	SKP: state.SKP
})

export default connect(mapStateToProps, {editSKP})(SkpEdit)