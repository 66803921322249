import React, {useState, useEffect} from 'react';
import { Table } from 'reactstrap';
import { connect } from "react-redux";
import {selectClient} from "../actions/clientActions";
import {selectSKP} from "../actions/skpActions";

const ClientTable = (props) => {
    const options = { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};

    const [clients, setClients] = useState([])
    const [h, setH] = useState('auto');

    useEffect(() => {
     setClients(props.data);
    }, [props.client])

    console.log(clients);

    const renderClients = (clients) => {
        return clients.map(({name, nip, licenseType, licenseNumber, createdAt, updatedAt, expiresIn, _id}) => (
            <tr className="tr-client" key={nip} onClick={() => {if(!props.skp) {props.selectClient(_id); props.selectSKP(''); setH('200px')} else {props.selectSKP(_id); props.selectClient('')}}}>
                <th scope="row">{name}</th>
                <th>{nip}</th>
                <th>{licenseNumber}</th>
                <th>{new Date(createdAt).toLocaleDateString('pl', options)}</th>
                <th>{new Date(updatedAt).toLocaleDateString('pl', options)}</th>
                {!props.skp ? <th>{licenseType === 'SAAS' ? new Date(expiresIn).toLocaleDateString('pl', options) : '-'}</th>: <></>}
            </tr>
        ))
    }

    return (
      <>
          <h2 className="clients-title">{props.title}</h2>
          <div style={{height: h}} className="table-container">
          <Table responsive>
              <thead>
                 <tr>
                  <th>Nazwa firmy</th>
                  <th>NIP</th>
                  <th>Klucz Licencji</th>
                  <th>Data utworzenia</th>
                  <th>Data ostatniej edycji</th>
                     {!props.skp ? <th>Data zakończenia</th> : <></>}
                </tr>
              </thead>
              <tbody>
              {renderClients(clients)}
              </tbody>
          </Table>
          </div>
      </>
    );
}

const mapStateToProps = (state) => ({
    client: state.client,
    SKP: state.SKP
});

export default connect(mapStateToProps, {selectClient, selectSKP})(ClientTable);