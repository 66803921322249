import React, {Component} from 'react';
import { connect } from "react-redux";
import {Button, Form, FormGroup, Input, Label, Modal, ModalBody, ModalHeader} from 'reactstrap';
import {editClient} from "../actions/clientActions";

class ClientEdit extends Component {

    state = {
        modal: false,
        name: '',
        nip: '',
        licenseType: 'Own',
        domain: '',
        domainStatic: '',
        IP: '',
        mailSMTP: '',
        mailUser: '',
        mailPass: '',
        monthsToExpire: 0
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
        const {selectedClient} = this.props.client;

        if(selectedClient){
            const cl = this.props.client.clients.filter(client => client._id === selectedClient)[0];
            if(cl){
                this.setState({
                    name: cl.name,
                    nip: cl.nip,
                    licenseType: cl.licenseType,
                    domain: cl.domain,
                    domainStatic: cl.domainStatic,
                    IP: cl.IP,
                    mailPass: cl.mailPass,
                    mailUser: cl.mailUser,
                    mailSMTP: cl.mailSMTP,
                    monthsToExpire: cl.monthsToExpire,
                    expiresIn: cl.expiresIn
                })
            }
        }
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value });
    }

    onSelectChange = (e) => {
        this.setState({licenseType: e.target.value})
    }

    onMonthChange = (e) => {
        this.setState({monthsToExpire: Number(e.target.value)})
    }

    onSubmit = (e) => {
        e.preventDefault();
        const date = new Date();
        var expireDate = new Date(date.setMonth(date.getMonth()+this.state.monthsToExpire)).toDateString();

        const newClient = {
            name: this.state.name,
            nip: this.state.nip,
            licenseType: this.state.licenseType,
            _id: this.props.client.selectedClient,
            domain: this.state.domain,
            domainStatic: this.state.domainStatic,
            IP: this.state.IP,
            mailPass: this.state.mailPass,
            mailUser: this.state.mailUser,
            mailSMTP: this.state.mailSMTP,
            monthsToExpire: this.state.monthsToExpire,
            expiresIn: expireDate
        }

        this.props.editClient(newClient);
        this.toggle();
    }

    render(){

        if(!this.props.client.selectedClient){
            return null;
        }

        return (
            <>
                <Button className="marginBottom" color="success" onClick={this.toggle}>Edytuj klienta</Button>
                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >
                    <ModalHeader toggle={this.toggle}>Edycja klienta</ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Label style={{marginBottom: '0.3rem'}} for="name">Nazwa firmy</Label>
                                <Input type="text" id="name"
                                       name="name" placeholder="Nazwa firmy"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.name} />
                                <Label style={{marginBottom: '0.3rem'}} for="nip">NIP</Label>
                                <Input type="text" id="nip"
                                       name="nip" placeholder="NIP"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.nip}/>
                                <Label style={{marginBottom: '0.3rem'}} for="licenseType">Typ licencji</Label>
                                <Input value={this.state.licenseType} onChange={this.onSelectChange} type="select"
                                       name="licenseType" id="licenseType">
                                    <option>Own</option>
                                    <option>SaaS</option>
                                </Input>
                                <Label style={{marginBottom: '0.3rem'}} for="domainA">Domena</Label>
                                <Input type="text" id="domainA"
                                       name="domainA" placeholder="Domena A"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.domain}/>
                                <Label style={{marginBottom: '0.3rem'}} for="domainB">Domena Static</Label>
                                <Input type="text" id="domainB"
                                       name="domainB" placeholder="Domena B"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.domainStatic}/>
                                <Label style={{marginBottom: '0.3rem'}} for="serverDatabase">IP</Label>
                                <Input type="text" id="IP"
                                       name="IP" placeholder="IP"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.IP}/>
                                <Label style={{marginBottom: '0.3rem'}} for="mailSMTP">Email SMTP</Label>
                                <Input type="text" id="mailSMTP"
                                       name="mailSMTP" placeholder="SMTP"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.IP}/>
                                <Label style={{marginBottom: '0.3rem'}} for="mailUser">Email User</Label>
                                <Input type="text" id="mailUser"
                                       name="mailUser" placeholder="Użytkownik"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.IP}/>
                                <Label style={{marginBottom: '0.3rem'}} for="mailPass">Email Pass</Label>
                                <Input type="text" id="mailPass"
                                       name="mailPass" placeholder="Hasło"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.IP}/>
                                <Label style={{marginBottom: '0.3rem'}} for="monthsToExpire">Ilość miesięcy</Label>
                                <Input value={this.state.monthsToExpire} onChange={this.onMonthChange} type="select"
                                       name="monthsToExpire" id="monthsToExpire">
                                    <option>12</option>
                                    <option>24</option>
                                    <option>36</option>
                                </Input>
                                <br />
                                <Button className="saveButton" color="dark">Edytuj</Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </>
        )
    }
}

const mapStateToProps = (state) => ({
    client: state.client
})

export default connect(mapStateToProps, {editClient})(ClientEdit)