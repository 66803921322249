export const GET_CLIENTS = 'GET_CLIENTS';
export const EDIT_CLIENTS = 'EDIT_CLIENTS';
export const DELETE_CLIENTS = 'DELETE_CLIENTS';
export const SELECT_CLIENT = 'SELECT_CLIENT';
export const ADD_CLIENT = 'ADD_CLIENT';
export const LOADING_CLIENT = 'LOADING_CLIENT';

export const GET_SKP = 'GET_SKP';
export const EDIT_SKP = 'EDIT_SKP';
export const DELETE_SKP = 'DELETE_SKP';
export const SELECT_SKP = 'SELECT_SKP';
export const ADD_SKP = 'ADD_SKP';