import {
	GET_SKP,
	EDIT_SKP,
	DELETE_SKP,
	SELECT_SKP,
	ADD_SKP
} from "./types";
import axios from 'axios';
export const getSKP = () => dispatch => {
	axios
		.get('/api/SKP')
		.then(res => {
			dispatch({
				type: GET_SKP,
				payload: res.data
			})})
};

export const selectSKP = (id) => {
	return {
		type: SELECT_SKP,
		payload: id
	}
}

export const addSKP = (SKP) => dispatch => {
	axios
		.post('/api/SKP', SKP)
		.then(res => dispatch({
			type: ADD_SKP,
			payload: res.data
		}))
}

export const editSKP = (SKP) => dispatch => {
	axios
		.patch(`/api/SKP/${SKP._id}`, SKP)
		.then(res => dispatch({
			type: EDIT_SKP,
			payload: res.data
		}))
}

export const deleteSKP = (id) => dispatch => {
	axios
		.delete(`/api/SKP/${id}`)
		.then(res => dispatch({
			type: DELETE_SKP,
			payload: id
		}))
}
