import React, {useState} from 'react';
import axios from 'axios';
import {Form, FormGroup, Input, Label, Button} from "reactstrap";

const Login = () => {

    const [email, setEmail] = useState();
    const [password, setPassword] = useState();

    const setMail = (e) => {
        setEmail(e.target.value);
    }

    const setPass = (e) => {
        setPassword(e.target.value);
    }

    const onSubmit = (e) => {
        e.preventDefault();

        axios.post('/api/clients/login', {email, password})
            .then(data => {
                if(data.data.success === true){
                    localStorage.setItem('gg', 'aa');
                    window.location.reload();
                } else{
                    localStorage.removeItem('gg');
                    window.location.reload();
                }
            })
            .catch(e => {
                console.log(e);
            })
    }

    return (
        <Form className="loginForm">
            <FormGroup>
                <Label for="exampleEmail">Email</Label>
                <Input type="email" name="email" id="exampleEmail" onChange={setMail} />
            </FormGroup>
            <br/>
            <FormGroup>
                <Label for="examplePassword">Hasło</Label>
                <Input type="password" name="password" id="examplePassword" onChange={setPass} />
            </FormGroup>
            <br/>
            <Button onClick={onSubmit}>Zaloguj</Button>
        </Form>
    )
}

export default Login