import React, {useState} from 'react';
import { connect } from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { deleteSKP } from "../actions/skpActions";

const SkpDelete = (props) => {

	const [modal, setModal] = useState(false);

	const toggle = () => {
		setModal(!modal);
	}

	if(!props.SKP.selectedSKP){
		return null;
	}

	const del = () => {
		props.deleteSKP(props.SKP.selectedSKP);
		toggle();
	}

	return (
		<>
			<Button className="marginBottom" color="danger" onClick={toggle}>Usuń klienta</Button>

			<Modal
				isOpen={modal}
				toggle={toggle}
			>
				<ModalHeader toggle={toggle}>Uwaga</ModalHeader>
				<ModalBody>
					Czy na pewno chcesz usunąć tego użytkownika?
				</ModalBody>
				<ModalFooter>
					<Button color="danger" onClick={() => {del()}}>Tak</Button>
					<Button color="secondary" onClick={() => {toggle()}}>Nie</Button>
				</ModalFooter>
			</Modal>
		</>
	);
};

const mapStateToProps = (state) => ({
	SKP: state.SKP
})

export default connect(mapStateToProps, {deleteSKP})(SkpDelete)