import React, { useState, Component} from "react";
import {
	Button,
	Modal,
	ModalHeader,
	ModalBody,
	Form,
	FormGroup,
	Label,
	Input
} from "reactstrap";
import { connect } from "react-redux";
import { addSKP } from '../actions/skpActions';
import { v4 as uuidv4 } from 'uuid';

class SKPModal extends Component{

	state = {
		modal: false,
		name: '',
		shortName: '',
		nip: '',
	}

	toggle = () => {
		this.setState({
			modal: !this.state.modal
		})
	}

	onChange = (e) => {
		this.setState({[e.target.name]: e.target.value });
	}

	onSubmit = (e) => {

		const newSKP = {
			name: this.state.name,
			shortName: this.state.shortName,
			nip: this.state.nip,
			licenseNumber: uuidv4(),
		}

		this.props.addSKP(newSKP);
		this.toggle();
	}

	render(){
		return (
			<div>
				<Button className="addNewSKP" color="primary" onClick={this.toggle} >Dodaj klienta SKP</Button>

				<Modal
					isOpen={this.state.modal}
					toggle={this.toggle}
				>

					<ModalHeader toggle={this.toggle}>Dodawanie klienta</ModalHeader>
					<ModalBody>
						<Form onSubmit={this.onSubmit}>
							<FormGroup>
								<Label style={{marginBottom: '0.3rem'}} for="name">Nazwa klienta</Label>
								<Input type="text" id="name"
								       name="name" placeholder="Nazwa Klienta"
								       onChange={this.onChange}
								       style={{marginBottom: '0.7rem'}}
								       value={this.state.name} />
								<Label style={{marginBottom: '0.3rem'}} for="shortName">Krótka nazwa</Label>
								<Input type="text" id="shortName"
								       name="shortName" placeholder="Nazwa do generowania"
								       onChange={this.onChange}
								       style={{marginBottom: '0.7rem'}}
								       value={this.state.name} />
								<Label style={{marginBottom: '0.3rem'}} for="nip">NIP</Label>
								<Input type="text" id="nip"
								       name="nip" placeholder="NIP"
								       onChange={this.onChange}
								       style={{marginBottom: '0.7rem'}}
								       value={this.state.nip} />
								<br />
								<Button className="saveButton" color="dark">Dodaj</Button>
							</FormGroup>
						</Form>
					</ModalBody>
				</Modal>
			</div>
		);
	}
}

const mapStateToProps = (state) => ({
	clients: state.clients,
	SKP: state.SKP
})

export default connect(mapStateToProps, {addSKP})(SKPModal);
