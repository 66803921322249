import React from 'react';

import { Provider } from "react-redux";
import store from "./store";

import {Row, Col, Button} from 'reactstrap';

import './App.css';
import ClientsCard from "./components/clientsCard";
import ClientServer from "./components/clientServer";
import ClientModules from "./components/clientModules";
import GenerateLicense from "./components/generateLicense";
import ClientEdit from "./components/clientEdit";
import ClientDelete from "./components/clientDelete";
import GenerateSKP from "./components/generateSKP";
import SkpEdit from "./components/skpEdit";
import SkpDelete from "./components/skpDelete";
import Login from "./components/Login";

class App extends React.Component {
    render(){
        return (
            <Provider store={store}>
                <div className="App">
                    {localStorage.getItem('gg') === 'aa' ? <>
                        <ClientsCard/>
                        <br/>
                        <Row>
                            <Col sm="6">
                                <ClientServer/>
                                <GenerateLicense/><GenerateSKP/><br/>
                                <ClientEdit/><SkpEdit/><br/>
                                <ClientDelete/><SkpDelete/>
                            </Col>
                            <Col sm="6">
                                <ClientModules/>
                            </Col>
                        </Row>
                    </> : <Login />}
                </div>
            </Provider>
        );
    }
}

export default App;
