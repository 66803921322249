import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import {
    Card,
    CardTitle,
    CardText,
    Button,
    Table,
    ModalHeader,
    ModalBody,
    Modal,
    ModalFooter
} from "reactstrap";
import {editClient} from "../actions/clientActions";


const ClientModules = (props) => {

    const options = { year: 'numeric', month: 'long', day: 'numeric', hour: 'numeric', minute: 'numeric'};

    const [client, setClient] = useState({});
    const [moduleModal, setModuleModal] = useState(false);
    const [modulesModal, setModulesModal] = useState(false);
    const [editedModule, setEditedModule] = useState('');

    useEffect(() => {

        const {selectedClient} = props.client;

        if(selectedClient){
            const cl = props.client.clients.filter(client => client._id === selectedClient)[0];
            if(cl){
                setClient(cl);
            }
        }
    }, [props.client.selectedClient])

    if(!props.client.selectedClient){
        return null;
    }

    const toggleModule = () => {
        setModuleModal(!moduleModal);
    }

    const toggleModules = () => {
        setModulesModal(!modulesModal);
    }

    const renderModules = () => {
        if(client.modules){
            return client.modules.map(({name, active, createdAt, updatedAt, _id}) => (
                <tr style={{backgroundColor: active ? 'lightgreen': 'inherit'}} className="tr-client " key={name} onClick={() => {openModuleModal(_id)}}>
                    <th scope="row">{name}</th>
                    <th>{active ? 'Tak' : 'Nie'}</th>
                    <th>{new Date(createdAt).toLocaleDateString('pl', options)}</th>
                    <th>{new Date(updatedAt).toLocaleDateString('pl', options)}</th>
                </tr>
            ))
        }
    }

    const openModuleModal = (id) => {
        setEditedModule(id);
        toggleModule()
    }

    const openModulesModal = () => {
        toggleModules()
    }

    const editModule = () => {
        const module = client.modules.find(module => module._id === editedModule);

        if(module){
            module.active = !module.active
        }
        props.editClient(client);
        toggleModule();
    }

    const editModules = () => {
        client.modules.forEach(module => {
            module.active = false
        })
        props.editClient(client);
        toggleModules();
    }

    return (
      <div>
          <Card body>
              <CardTitle tag="h3">Posiadane licencje</CardTitle>
                  <Button className="modules-card-button" color="danger" onClick={openModulesModal}>Deaktywuj wszystkie</Button>
                  <Table responsive>
                      <thead>
                      <tr>
                          <th>Nazwa</th>
                          <th>Aktywna</th>
                          <th>Data utworzenia</th>
                          <th>Data ostatniej edycji</th>
                      </tr>
                      </thead>
                      <tbody>
                      {renderModules()}
                      </tbody>
                  </Table>
          </Card>
          <Modal
              isOpen={moduleModal}
              toggle={toggleModule}
          >
              <ModalHeader toggle={toggleModule}>Uwaga</ModalHeader>
              <ModalBody>
                  Czy na pewno chcesz wykonać tą operację?
              </ModalBody>
              <ModalFooter>
                  <Button color="danger" onClick={() => {editModule()}}>Tak</Button>
                  <Button color="secondary" onClick={() => {toggleModule()}}>Nie</Button>
              </ModalFooter>
          </Modal>
          <Modal
              isOpen={modulesModal}
              toggle={toggleModules}
          >
              <ModalHeader toggle={toggleModules}>Uwaga</ModalHeader>
              <ModalBody>
                  Czy na pewno chcesz wykonać tą operację?
              </ModalBody>
              <ModalFooter>
                  <Button color="danger" onClick={() => {editModules()}}>Tak</Button>
                  <Button color="secondary" onClick={() => {toggleModules()}}>Nie</Button>
              </ModalFooter>
          </Modal>
      </div>
    );

}

const mapStateToProps = (state) => ({
    client: state.client
});

export default connect(mapStateToProps, {editClient})(ClientModules)

