import {
	GET_SKP,
	EDIT_SKP,
	DELETE_SKP,
	SELECT_SKP,
	ADD_SKP
} from "../actions/types";

const initialState = {
	SKP: [],
	selectedSKP: undefined,
	loading: false
}

export default function(state = initialState, action){
	switch(action.type){
		case GET_SKP:
			return {
				...state,
				SKP: action.payload,
				loading: false
			}
		case SELECT_SKP:
			return {
				...state,
				selectedSKP: action.payload
			}
		case ADD_SKP:
			return {
				...state,
				SKP: [action.payload, ...state.SKP]
			}
		case DELETE_SKP:
			return {
				...state,
				SKP: state.SKP.filter(SKP => SKP._id !== action.payload)
			}
		case EDIT_SKP:
			return {
				...state,
				SKP: state.SKP.map(SKP => {
					if(SKP._id === action.payload._id){
						return {
							...action.payload
						}
					} else{
						return {
							...SKP
						};
					}
				})
			}
		default:
			return state;
	}
}