import React, { useState, Component} from "react";
import {
    Button,
    Modal,
    ModalHeader,
    ModalBody,
    Form,
    FormGroup,
    Label,
    Input
} from "reactstrap";
import { connect } from "react-redux";
import { addClient } from '../actions/clientActions';
import { v4 as uuidv4 } from 'uuid';
import CSVReader from 'react-csv-reader';
import { FaFileCsv } from 'react-icons/fa';
import generator from 'generate-password';

class ClientModal extends Component{

    state = {
        modal: false,
        name: '',
        shortName: '',
        IP: '',
        nip: '',
        licenseType: 'Standalone',
        mailSMTP: '',
        mailPass: '',
        mailUser: '',
        domain: '',
        domainStatic: '',
        modules: [],
        filename: '',
        monthsToExpire: 12,
        expiresIn: ''
    }

    toggle = () => {
        this.setState({
            modal: !this.state.modal
        })
    }

    onChange = (e) => {
        this.setState({[e.target.name]: e.target.value });
    }

    onSelectChange = (e) => {
        this.setState({licenseType: e.target.value})
    }

    onMonthChange = (e) => {
        this.setState({monthsToExpire: Number(e.target.value)})
    }

    onSubmit = (e) => {
        e.preventDefault();
        const date = new Date();
        var expireDate = new Date(date.setMonth(date.getMonth()+this.state.monthsToExpire)).toDateString();

        var password = generator.generate({
            length: 15,
            numbers: true
        });

        const newClient = {
            name: this.state.name,
            shortName: this.state.shortName,
            IP: this.state.IP,
            nip: this.state.nip,
            licenseType: this.state.licenseType,
            licenseNumber: uuidv4(),
            domain: this.state.domain,
            sftpHost: this.state.ip,
            sftpPort: 26,
            sftpUser: this.state.shortName,
            sftpPass: password,
            mailUser: this.state.mailUser,
            mailSMTP: this.state.mailSMTP,
            mailPass: this.state.mailPass,
            domainStatic: this.state.domainStatic,
            serverDatabase: `mongodb://${this.state.shortName}:${password}@${this.state.IP}:30020/testDB?authSource=admin`,
            modules: this.state.modules,
            monthsToExpire: this.state.monthsToExpire,
            expiresIn: expireDate
        }

        this.props.addClient(newClient);
        this.toggle();
    }

    render(){
        return (
            <div>
                <Button className="addNewClient" color="primary" onClick={this.toggle} >Dodaj klienta</Button>

                <Modal
                    isOpen={this.state.modal}
                    toggle={this.toggle}
                >

                    <ModalHeader toggle={this.toggle}>Dodawanie klienta <CSVReader inputStyle={{visibility: 'hidden'}} label={<FaFileCsv/>} onFileLoaded={(data, fileInfo) => {
                        this.setState({
                            name: data[0][0],
                            nip: data[0][1],
                            modules: data[0][2],
                            licenseType: data[0][3],
                            filename: fileInfo.name
                        })
                    }} /><p className="filename">{this.state.filename}</p></ModalHeader>
                    <ModalBody>
                        <Form onSubmit={this.onSubmit}>
                            <FormGroup>
                                <Label style={{marginBottom: '0.3rem'}} for="name">Nazwa klienta</Label>
                                <Input type="text" id="name"
                                       name="name" placeholder="Nazwa Klienta"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.name} />
                                <Label style={{marginBottom: '0.3rem'}} for="shortName">Krótka nazwa</Label>
                                <Input type="text" id="shortName"
                                       name="shortName" placeholder="Nazwa do generowania"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.shortName} />
                                <Label style={{marginBottom: '0.3rem'}} for="nip">NIP</Label>
                                <Input type="text" id="nip"
                                       name="nip" placeholder="NIP"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}}
                                       value={this.state.nip} />
                                <Label style={{marginBottom: '0.3rem'}} for="licenseType">Typ licencji</Label>
                                <Input value={this.state.licenseType} onChange={this.onSelectChange} type="select"
                                       name="licenseType" id="licenseType">
                                    <option>Standalone</option>
                                    <option>SAAS</option>
                                </Input>
                                <Label style={{marginBottom: '0.3rem'}} for="domain">Domena</Label>
                                <Input type="text" id="domain"
                                       name="domain" placeholder="Domena"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}} />
                                <Label style={{marginBottom: '0.3rem'}} for="domainStatic">Domena Static</Label>
                                <Input type="text" id="domainStatic"
                                       name="domainStatic" placeholder="Domena Static"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}} />
                                <Label style={{marginBottom: '0.3rem'}} for="IP">Adres IP</Label>
                                <Input type="text" id="IP"
                                       name="IP" placeholder="Adres IP"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}} />
                                <Label style={{marginBottom: '0.3rem'}} for="mailSMTP">Email SMTP</Label>
                                <Input type="text" id="mailSMTP"
                                       name="mailSMTP" placeholder="SMTP"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}} />
                                <Label style={{marginBottom: '0.3rem'}} for="mailUser">Email User</Label>
                                <Input type="text" id="mailUser"
                                       name="mailUser" placeholder="Użytkownik"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}} />
                                <Label style={{marginBottom: '0.3rem'}} for="mailPass">Email Pass</Label>
                                <Input type="text" id="mailPass"
                                       name="mailPass" placeholder="Hasło"
                                       onChange={this.onChange}
                                       style={{marginBottom: '0.7rem'}} />
                                <Label style={{marginBottom: '0.3rem'}} for="monthsToExpire">Ilość miesięcy</Label>
                                <Input value={this.state.monthsToExpire} onChange={this.onMonthChange} type="select"
                                       name="monthsToExpire" id="monthsToExpire">
                                    <option>12</option>
                                    <option>24</option>
                                    <option>36</option>
                                </Input>
                                <br />
                            <Button className="saveButton" color="dark">Dodaj</Button>
                            </FormGroup>
                        </Form>
                    </ModalBody>
                </Modal>
            </div>
        );
    }
}

const mapStateToProps = (state) => ({
    clients: state.clients
})

export default connect(mapStateToProps, {addClient})(ClientModal);
