import {
    GET_CLIENTS,
    EDIT_CLIENTS,
    DELETE_CLIENTS,
    SELECT_CLIENT,
    ADD_CLIENT,
    LOADING_CLIENT
} from "../actions/types";

const initialState = {
    clients: [],
    selectedClient: undefined,
    loading: false
}

export default function(state = initialState, action){
    switch(action.type){
        case GET_CLIENTS:
            return {
                ...state,
                clients: action.payload,
                loading: false
            }
        case SELECT_CLIENT:
            return {
                ...state,
                selectedClient: action.payload
            }
        case ADD_CLIENT:
            return {
                ...state,
                clients: [action.payload, ...state.clients]
            }
        case LOADING_CLIENT:
            return {
                ...state,
                loading: true
            }
        case DELETE_CLIENTS:
            return {
                ...state,
                clients: state.clients.filter(client => client._id !== action.payload)
            }
        case EDIT_CLIENTS:
            return {
                ...state,
                clients: state.clients.map(client => {
                    if(client._id === action.payload._id){
                        console.log(action.payload);
                        return {
                            ...action.payload
                        }
                    } else{
                        return {
                            ...client
                        };
                    }
                })
            }
        default:
            return state;
    }
}