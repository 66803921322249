import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {v4 as uuidv4} from "uuid";
import {editSKP} from "../actions/skpActions";

const GenerateSKP = (props) => {

	const [modal, setModal] = useState(false);
	const [client, setClient] = useState({});

	useEffect(() => {

		const {selectedSKP} = props.SKP;

		if(selectedSKP){
			const cl = props.SKP.SKP.filter(client => client._id === selectedSKP)[0];
			if(cl){
				setClient(cl);
			}
		}
	}, [props.SKP.selectedSKP])

	const toggle = () => {
		setModal(!modal);
	}

	if(!props.SKP.selectedSKP){
		return null;
	}

	const generateNewLicense = () => {
		client.licenseNumber = uuidv4()
		props.editSKP(client);
		toggle();
	}

	return (
		<>
			<Button style={{marginTop: '0.7rem'}} className="marginBottom" color="info" onClick={toggle}>Wygeneruj nowy numer licencji</Button>

			<Modal
				isOpen={modal}
				toggle={toggle}
			>
				<ModalHeader toggle={toggle}>Uwaga</ModalHeader>
				<ModalBody>
					Czy na pewno chcesz wygenerować nowy kod licencyjny?
				</ModalBody>
				<ModalFooter>
					<Button color="danger" onClick={() => {generateNewLicense()}}>Tak</Button>
					<Button color="secondary" onClick={() => {toggle()}}>Nie</Button>
				</ModalFooter>
			</Modal>
		</>
	)
}

const mapStateToProps = (state) => ({
	SKP: state.SKP
})

export default connect(mapStateToProps, {editSKP})(GenerateSKP)