import React, {useState} from 'react';
import { connect } from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import { deleteClient } from "../actions/clientActions";

const ClientDelete = (props) => {

    const [modal, setModal] = useState(false);

    const toggle = () => {
        setModal(!modal);
    }

    if(!props.client.selectedClient){
        return null;
    }

    const del = () => {
        props.deleteClient(props.client.selectedClient);
        toggle();
    }

    return (
        <>
            <Button className="marginBottom" color="danger" onClick={toggle}>Usuń klienta</Button>

            <Modal
                isOpen={modal}
                toggle={toggle}
            >
                <ModalHeader toggle={toggle}>Uwaga</ModalHeader>
                <ModalBody>
                    Czy na pewno chcesz usunąć tego użytkownika?
                </ModalBody>
                <ModalFooter>
                    <Button color="danger" onClick={() => {del()}}>Tak</Button>
                    <Button color="secondary" onClick={() => {toggle()}}>Nie</Button>
                </ModalFooter>
            </Modal>
        </>
    )
}

const mapStateToProps = (state) => ({
    client: state.client
})

export default connect(mapStateToProps, {deleteClient})(ClientDelete)