import React, {useEffect, useState} from 'react';
import { connect } from "react-redux";
import {Button, Modal, ModalBody, ModalFooter, ModalHeader} from 'reactstrap';
import {v4 as uuidv4} from "uuid";
import {editClient} from "../actions/clientActions";

const GenerateLicense = (props) => {

    const [modal, setModal] = useState(false);
    const [client, setClient] = useState({});

    useEffect(() => {

        const {selectedClient} = props.client;

        if(selectedClient){
            const cl = props.client.clients.filter(client => client._id === selectedClient)[0];
            if(cl){
                setClient(cl);
            }
        }
    }, [props.client.selectedClient])

    const toggle = () => {
        setModal(!modal);
    }

    if(!props.client.selectedClient){
        return null;
    }

    const generateNewLicense = () => {
        client.licenseNumber = uuidv4()
        props.editClient(client);
        toggle();
    }

    return (
    <>
        <Button style={{marginTop: '0.7rem'}} className="marginBottom" color="info" onClick={toggle}>Wygeneruj nowy numer licencji</Button>

        <Modal
            isOpen={modal}
            toggle={toggle}
        >
            <ModalHeader toggle={toggle}>Uwaga</ModalHeader>
            <ModalBody>
                Czy na pewno chcesz wygenerować nowy kod licencyjny?
            </ModalBody>
            <ModalFooter>
                <Button color="danger" onClick={() => {generateNewLicense()}}>Tak</Button>
                <Button color="secondary" onClick={() => {toggle()}}>Nie</Button>
            </ModalFooter>
        </Modal>
    </>
    )
}

const mapStateToProps = (state) => ({
    client: state.client
})

export default connect(mapStateToProps, {editClient})(GenerateLicense)