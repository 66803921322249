import React, {useState, useEffect} from 'react';
import { connect } from "react-redux";
import {Card, CardTitle, CardText} from "reactstrap";


const ClientServer = (props) => {

    const [client, setClient] = useState({});

    useEffect(() => {

        const {selectedClient} = props.client;

        if(selectedClient){
            const cl = props.client.clients.filter(client => client._id === selectedClient)[0];
            if(cl){
                setClient(cl);
            }
        }
    }, [props.client.selectedClient])

    if(!props.client.selectedClient){
        return null;
    }

    return (
      <>
          <Card body>
              <CardTitle tag="h3">Dane serwera</CardTitle>
              <CardText>
                  <b>Domena: </b>{client.domain}<br/>
                  <b>Domena Static: </b>{client.domainStatic}<br/>
                  <b>Email SMTP: </b>{client.mailSMTP}<br/>
                  <b>Email User: </b>{client.mailUser}<br/>
                  <b>Email Pass: </b>{client.mailPass}
              </CardText>
          </Card>
      </>
    );

}

const mapStateToProps = (state) => ({
    client: state.client
});

export default connect(mapStateToProps, null)(ClientServer)

