import React, {useState, useEffect} from 'react';
import { TabContent, TabPane, Nav, NavItem, NavLink, Card, Button} from 'reactstrap';
import classnames from 'classnames';
import { connect } from "react-redux";
import {getClients} from "../actions/clientActions";
import {getSKP} from "../actions/skpActions";
import ClientTable from "./clientTable";
import ClientModal from "./clientModal";
import SKPModal from "./SKPModal";

const ClientsCard = (props) => {

    const [activeTab, setActiveTab] = useState('1');

    const toggle = tab => {
        if(activeTab !== tab) setActiveTab(tab);
    }

    useEffect(() => {
        if(props){
            props.getClients();
            props.getSKP();
        }
    }, [])

    const { clients } = props.client;
    const { SKP } = props.SKP
    const skp = SKP;
    const ownClients = clients.filter(client => client.licenseType === 'Standalone');
    const saasClients = clients.filter(client => client.licenseType === 'SAAS');

    if(skp.length < 1 && clients.length < 1){
        return <></>
    }

    return (
        <div>
        <ClientModal/>
        <SKPModal/>
        <Card body outline color='secondary' className="clients-card">
            <Nav tabs>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === '1'})}
                        onClick={() => {toggle('1');}}
                        >
                        Standalone
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === '2'})}
                        onClick={() => {toggle('2');}}
                    >
                        SaaS
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        className={classnames({active: activeTab === '3'})}
                        onClick={() => {toggle('3');}}
                    >
                        SKP
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
                <TabPane tabId="1">
                    <ClientTable title="Licencja na własność" data={ownClients}/>
                </TabPane>
                <TabPane tabId="2">
                    <ClientTable title="Licencja SaaS" data={saasClients}/>
                </TabPane>
                <TabPane tabId="3">
                    <ClientTable title="Licencje SKP" skp={true} data={skp}/>
                </TabPane>
            </TabContent>
        </Card>
        </div>
    );
}

const mapStateToProps = (state) => ({
    client: state.client,
    SKP: state.SKP
});

export default connect(mapStateToProps, {getClients, getSKP})(ClientsCard);